export async function generateSuggestions(messages, userId) {

    const backendUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:4000";
    
    try {
        const response = await fetch(`${backendUrl}/generate-suggestions`, { 
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ messages, userId }),
            credentials: 'include'
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error("Response not OK:", response.status, errorText);
            throw new Error(`Failed to fetch suggestions: ${errorText}`);
        }

        const data = await response.json();
        console.log("Received suggestions:", data.suggestions);
        return data.suggestions || [];
    } catch (error) {
        console.error("Error generating suggestions:", error);
        return [];
    }
}