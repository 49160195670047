import React, {useState} from 'react'
import { Button,Select } from "flowbite-react";


export default function PromptReminderForm({prompt, handleDayChange, parseDateTime}) {
  const [day, setDay] = useState(parseDateTime(prompt.set_day) || parseDateTime(prompt.updated_at));
  const [frequency, setFrequency] = useState(prompt.frequency);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleDayChange(e, day, frequency);
  }

  return (
    <form className='flex flex-row gap-2 flex-grow' data-key={prompt.id} onSubmit={handleSubmit}>
        <Select
            value={day}
            onChange={(e) =>
                setDay(e.target.value)
            }
            className={`flex-1 ${prompt.is_paused ? "opacity-50" : ""}`}
            disabled={prompt.is_paused}
        >
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day, index) => (
                <option key={day} value={index+1}>
                {day}
                </option>
            ))}
        </Select>
        <Select
            value={frequency}
            onChange={(e) =>
                setFrequency(e.target.value)
            }
            className={`flex-1 ${prompt.is_paused ? "opacity-50" : ""}`}
            disabled={prompt.is_paused}
        >
            <option value="never">Never</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
        </Select>
        <Button type="submit">Save</Button>
    </form>
  )
}
