import React, { forwardRef } from "react";
import PromptLibrary from "./PromptLibrary";
// import Widget from "./Widget";
// import { Button } from "flowbite-react"

const RightNav = forwardRef(
  (
    {
      onSelectPrompt,
      isOpen,
      setIsOpen,
      toggleSidebar,
      closeSidebar,
      isMobile,
      isTablet,
      selectedCategory,
      setSelectedCategory,
    }, ref
  ) => {
    const handleCloseSidebar = () => {
      if (isMobile || isTablet) {
        closeSidebar();
      }
    };

    // function to add a new widget
    // const addWidget = () => {
    //   const newWidget = {
    //     id: Date.now().toString(),
    //     title: "New Widget",
    //     content: "New Widget Content",
    //   };
    //   setWidgets((prevWidgets) => [...prevWidgets, newWidget]);
    // };

    // function to remove a widget
    // const removeWidget = (id) => {
    //   setWidgets((prevWidgets) => prevWidgets.filter((widget) => widget.id !== id));
    // };

    return (
      <div className="h-full overflow-y-auto p-4">
        <PromptLibrary
          onSelectPrompt={(prompt) => {
            onSelectPrompt(prompt);
            handleCloseSidebar();
          }}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />

        {/* <ToolsMenu onSelectTool={closeSidebar} />  */}

        {/* MORE MENU  ITEMS*/}

        {/* WIDGETS */}

        {/* <Button onClick={addWidget}>Add Widget</Button>


            {widgets.map((widget) => (
              <Widget
                key={widget.id}
                id={widget.id}
                title={widget.title}
                content={widget.content}
                onRemove={() => removeWidget(widget.id)}
              />
            ))} */}
      </div>
    );
  }
);

export default RightNav;
