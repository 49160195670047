import React, { useState } from "react";
import { Card, Modal } from "flowbite-react";
import WelcomeFileUpload from "../components/WelcomeFileUpload";
import WelcomeIntegrations from "../components/WelcomeIntegrations";
import OnboardingChat from "../components/OnboardingChat";
import OnboardingProgress from "../components/OnboardingProgress";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ConfettiEffect from "../components/ConfettiEffect";
import Logo from '../components/Logo';


function WelcomePage() {

  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);


  const steps = [
    {
      title: (
        <div className="flex flex-row items-center gap-4">
          <Logo size="small" clickable={false} showText={false} />
          <h2 className="text-xl font-semibold text-darkBrand">
          Chat with Sindre
          </h2>
        </div>
      ),
      subtitle: "",
      help: "Chat with Sindre to personalize your experience.",
      component: (
        <OnboardingChat
          userData={userData}
          setUserData={setUserData}
          onComplete={() => handleNextStep()}
          onPrevious={() => handlePreviousStep()}
        />
      ),
    },
    {
      title: "Upload Knowledge",
      subtitle: "Upload relevant product information, such as Product Briefs or PRDs.",
      help: "Upload relevant documents to enhance Sindre's understanding",
      component: (
        <WelcomeFileUpload
          onComplete={() => handleNextStep()}
          onPrevious={() => handlePreviousStep()}
        />
      ),
    },
    {
      title: "Connect Tools",
      subtitle: "Connect Sindre to the tools you use for better support.",
      help: "Integrate your favorite tools for a seamless experience",
      component: (
        <WelcomeIntegrations
          onComplete={() => handleCompleteOnBoarding()}
          onPrevious={() => handlePreviousStep()}
        />
      ),
    },
  ];

  const handleNextStep = () => {
    if (step < steps.length) {
      setStep(step + 1);
    } else {
      handleCompleteOnBoarding();
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleStepClick = (index) => {
    setStep(index + 1);
  };

  const handleCompleteOnBoarding = () => {
    setShowConfetti(true);
    setShowModal(true);
    setTimeout(() => {
      setShowConfetti(false);
      setShowModal(false);
      navigate("/");
    }, 4000);
  };

  // Framer Motion
  const pageVariants = {
    initial: { opacity: 0, y: 50 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };


  return (
    <div className="min-h-screen bg-gradient-to-br from-cyan-50 to-lightBrand flex items-center justify-center py-4 px-4 sm:py-6 sm:px-6">
      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          className="w-full max-w-7xl"
        >
          <Card className="bg-white shadow-lg rounded-lg overflow-hidden">
            {/* Left column for desktop, top section for mobile */}
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/3 p-4 sm:p-6 bg-gradient-to-b from-purple-100 to-white">
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <h1 className="text-2xl sm:text-3xl font-bold text-brandColor mb-2">
                    Welcome to Sindre
                  </h1>
                  <p className="text-sm text-gray-600 mb-4 sm:mb-6">
                  Let's get you set up to make the most of Sindre's support.
                  </p>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="mb-4"
                >
                  <OnboardingProgress
                    steps={steps}
                    currentStep={step}
                    onStepClick={handleStepClick}
                  />
                </motion.div>
              </div>
              <div className="w-full md:w-2/3 p-4 sm:p-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 }}
                >
                  <h2 className="text-xl sm:text-2xl font-semibold text-darkBrand mb-1">
                    {steps[step - 1].title}
                  </h2>
                  <h3 className="text-sm text-gray-500 mb-4">
                    {steps[step - 1].subtitle}
                  </h3>
                  {steps[step - 1].component}
                </motion.div>
              </div>
            </div>
          </Card>
        </motion.div>
      </AnimatePresence>

      <ConfettiEffect show={showConfetti} />

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        size="md"
        popup
      >
        <Modal.Header className="hidden" />
        <Modal.Body className="p-6">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center"
          >
            <h2 className="text-2xl font-bold text-center text-darkBrand mb-4">
              You're all set with Sindre!
            </h2>
            <motion.img
              src="https://uploads-ssl.webflow.com/66a0daf034f5b2211c4a3e03/66abab9d9f28c7211240a58b_Sindre%20circle%201280x1280.png"
              alt="Sindre logo"
              className="w-24 h-24 mb-4"
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            />
            <p className="text-md text-darkBrand mb-6 text-center">
              Get ready to revolutionize your product management workflow!
            </p>
          </motion.div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WelcomePage;