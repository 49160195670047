import React, { useState } from 'react';
import DiscoveryChat from '../components/DiscoveryChat';
import DiscoveryTable from '../components/DiscoveryTable';
import BackToHomeBtn from '../components/BackToHomeBtn'; // Add this import
import { initializeZepTableSession } from '../utils/initializeZepTable';
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-hot-toast';

const DiscoveryPage = () => {
  const [tableContent, setTableContent] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const { user } = useAuth();

  const handleComplete = (content) => {
    setTableContent(content);
    setUserPrompt(content);
  };

  const handleRefresh = async () => {
    if (!user) {
      toast.error('User not authenticated');
      return;
    }

    if (!tableContent) {
      toast.error('No content to refresh');
      return;
    }

    try {
      const sessionId = uuidv4();
      const result = await initializeZepTableSession(
        user.id,
        user.user_metadata.full_name,
        tableContent,
        sessionId
      );

      if (result.success) {
        setTableContent(result.content); // Update tableContent with new content from backend
        toast.success('Table refreshed successfully');
      } else {
        toast.error('Failed to refresh table');
      }
    } catch (error) {
      console.error('Error refreshing table:', error);
      toast.error('Failed to refresh table');
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      {/* Header section for buttons */}
      <div className="flex items-center p-8 border-b border-gray-200 relative">
        <BackToHomeBtn />
      </div>
      {/* Main content area */}
      <div className="flex flex-1 overflow-hidden">
        {/* Left Column - DiscoveryTable */}
        <div className="w-3/4 p-4 border-r border-gray-200 overflow-auto">
          <DiscoveryTable content={tableContent} />
        </div>

        {/* Right Column - DiscoveryChat */}
        <div className="w-1/4 p-4 overflow-auto">
          <DiscoveryChat onComplete={handleComplete} userPrompt={userPrompt} />
        </div>
      </div>
    </div>
  );
}

export default DiscoveryPage;
