import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Onboarding from "./Onboarding";
import { getSupabase } from "../utils/supabase";
import { useAuth } from "../context/AuthContext";
import OpenAI from "openai";
import socketIOClient from "socket.io-client";
import PromptBar from "./PromptBar";
import { v4 as uuidv4 } from "uuid";
import { Button } from "flowbite-react";
import { motion, AnimatePresence } from "framer-motion";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const supabase = getSupabase();
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const OnboardingChat = ({ onComplete }) => {
  const { user } = useAuth();
  const chatLogEndRef = useRef(null);
  const [chatLog, setChatLog] = useState([]);
  const [conversationEnded, setConversationEnded] = useState(false);
  const [userPrompt, setUserPrompt] = useState(null);
  const [showNextStepCue, setShowNextStepCue] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [socket, setSocket] = useState(null);
  const [err, setErr] = useState(false);
  const [messageCounter, setMessageCounter] = useState(0);
  const [transcription, setTranscription] = useState("");
  const textareaRef = useRef(null);
  const [sessionId] = useState(uuidv4());
  const [userMessageCounter, setUserMessageCounter] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  // Greeting based on the time of day
  const greeting = useMemo(() => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning"
    if (hour < 18) return "Good afternoon"
    return "Good evening"
  }, [])

  // Initialize the zep session
  const initializeZepSession = async () => {
    if (user && userPrompt) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/init-zep-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.id,
            fullName: user.user_metadata.full_name,
            userPrompt: userPrompt,
            sessionId: sessionId,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to initialize Zep session');
        }

        const data = await response.json();
        console.log('Zep session initialized:', data.sessionId);
      } catch (error) {
        console.error('Error initializing Zep session:', error);
      }
    }
  }

  useEffect(() => {
    initializeZepSession();
  }, [userPrompt]);

  // Socket connection
  useEffect(() => {
    if (socket) {
      socket.on("start", (sessionId) => {
        console.log(`Started streaming for session ${sessionId}`);
        setIsStreaming(true);
      });

      socket.on("end", (sessionId) => {
        console.log(`Finished streaming for session ${sessionId}`);
        setIsStreaming(false);
        setMessageCounter(chatLog.length);
      });

      socket.on("token", (token) => {
        setChatLog((prevLog) => {
          const newLog = [...prevLog];
          const lastMessage = newLog[newLog.length - 1];
          if (lastMessage && lastMessage.role === "ai") {
            return [
              ...newLog.slice(0, -1),
              { ...lastMessage, content: lastMessage.content + token },
            ];
          } else {
            return [...newLog, { role: "ai", content: token }];
          }
        });
      });

      socket.on("error", (error) => {
        console.error("Error:", error);
      });
    }
  }, [socket]);

  // Scroll to bottom
  useEffect(() => scrollToBottom(), [chatLog]);

  const scrollToBottom = () => {
    chatLogEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isStreaming && inputPrompt.trim() !== "") {
      setMessageCounter((prev) => prev + 1);
      setInputPrompt("");

      const userMessage = { role: "human", content: inputPrompt };
      setChatLog((prevLog) => [...prevLog, userMessage]);
      setUserMessageCounter((prev) => prev + 1);
      try {
        const socket = socketIOClient(backendUrl);
        setSocket(socket);

        socket.emit('onboarding', {
          message: inputPrompt,
          sessionId: sessionId
        });

      } catch (error) {
        setErr(true);
        console.error(error);
      }
    }
  };

  // Handle textarea input
  const handleTextareaInput = (e) => {
    const textarea = e.target;
    setInputPrompt(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const adjustTextareaHeight = (textarea) => {
    const lineHeight = 24;
    const maxHeight = lineHeight * 10;
  
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, maxHeight);
    textarea.style.height = `${newHeight}px`;
    textarea.style.overflowY = textarea.scrollHeight > maxHeight ? "scroll" : "hidden";
  };

  // Handle key down
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      return;
    }
  };

  // Handle conversation end
  useEffect(() => {
    if (!conversationEnded && chatLog.length > 0) {
      const aiMessages = chatLog.filter(
        (message) => message.role === "ai"
      ).length;
      const userMessages = chatLog.filter(
        (message) => message.role === "human"
      ).length;
      const lastMessage = chatLog[chatLog.length - 1];

      if (
        (lastMessage.role === "ai" &&
          lastMessage.content
            ?.toLowerCase()
            .includes("perfect" || "ready" || "interesting")) ||
        aiMessages >= 3 ||
        userMessages >= 3
      ) {
        setConversationEnded(true);
        handleEndConversation();
        setShowNextStepCue(true);
      }
    }
  }, [chatLog, conversationEnded]);

  // Start onboarding conversation
  const startOnboardingConversation = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const { data: userData, error: fetchError } = await supabase
        .from("users")
        .select("user_prompt")
        .eq("id", user.id)
        .single();

      if (fetchError) throw fetchError;

      if (userData && userData.user_prompt) {
        setUserPrompt(
          `${greeting} ${user.user_metadata.full_name}!\n\nI'm Sindre, your digital Product Manager!\n\nI did some research and found this about you:\n\n${userData.user_prompt}\n\n  \n**Does this seem correct?**`
        );
      } else {
        setUserPrompt(
          `${greeting} ${user.user_metadata.full_name}!\n\nI'm Sindre, your digital Product Manager!\n\nI'm here to help you with your product management needs.\n\nTo get started, could you tell me a bit about:\n\n1. What is your role?\n2. The type of product or project you're working on?\n\n  \n**This information will help me tailor my assistance to your needs.**`
        );
      }
    } catch (error) {
      console.error("Error fetching user information:", error);
      setUserPrompt("Error fetching user information");
    }
  }, [user, greeting]);

  useEffect(() => {
    if (user && !userPrompt) {
      startOnboardingConversation();
    }
  }, [user, userPrompt, startOnboardingConversation]);

  useEffect(() => {
    if (userPrompt) {
      setChatLog([{ role: "ai", content: userPrompt }]);
    }
  }, [userPrompt]);

  // Handle conversation end
  const handleEndConversation = async () => {
    const prompt = await makePrompt();
    await storePrompt(prompt);
  };

  // Store prompt
  const storePrompt = async (prompt) => {
    const { error } = await supabase
      .from("users")
      .update({
        user_prompt: prompt,
        default_user_prompt: prompt,
      })
      .eq("id", user.id);

    if (error) {
      console.error("Error storing prompt:", error);
    }
  };

  // Make prompt
  const makePrompt = async () => {
    const history = chatLog
      .map((message) => `${message.role}: ${message.content}`)
      .join("\n");

    const completion = await openai.chat.completions.create({
      messages: [
        { role: "system", content: "You will be given a short conversation that typically contains information about a user's company, role, and the product or project they are working on. Your task is to create a concise summary of this conversation that can be used by an AI to generate more relevant outputs when interacting with this user." },
        {
          role: "user",
          content: `Make a summary of this conversation as a description of the user : ${history}`,
        },
      ],
      model: "gpt-4-1106-preview",
    });

    return completion.choices[0].message.content;
  };

  // Set button state
  useEffect(() => {
    setIsNextButtonDisabled(userMessageCounter < 2);
  }, [userMessageCounter]);

  // Animation variants
  const messageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const pulseVariants = {
    pulse: {
      scale: [1, 1.05, 1],
      transition: { duration: 1, repeat: Infinity }
    }
  };

  const getMessageAndColor = () => {
    if (userMessageCounter === 0) {
      return { message: "Write 2 more messages to continue", color: "text-brandColor", icon: "✍️" };
    } else if (userMessageCounter === 1) {
      return { message: "Write 1 more message to continue", color: "text-brandColor", icon: "🏁" };
    } else {
      return { message: "You can continue now!", color: "text-brandColor", icon: "🎉" };
    }
  };

  const { message, color } = getMessageAndColor();

  return (
    <div className="flex flex-col h-full bg-white rounded-lg">
      <div className="flex-grow overflow-y-auto p-1 sm:p-2 flex flex-col">
        <Onboarding
          chatLog={chatLog}
          setChatLog={setChatLog}
          userPrompt={userPrompt}
          chatLogEndRef={chatLogEndRef}
        />
      </div>

      <div className="mt-1 sm:mt-2">
        <PromptBar
          inputPrompt={inputPrompt}
          handleTextareaInput={handleTextareaInput}
          handleSubmit={handleSubmit}
          handleKeyDown={handleKeyDown}
          setTranscription={setTranscription}
          isStreaming={isStreaming}
        />
      </div>

      <div className="flex justify-end items-center w-full mt-4 sm:mt-6 space-x-2 sm:space-x-4 p-2 sm:p-0">
        <AnimatePresence mode="wait">
          <motion.div
            key={message}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={messageVariants}
            className={`${color} font-medium text-xs sm:text-sm`}
          >
            {userMessageCounter >= 2 ? (
              <motion.span variants={pulseVariants} animate="pulse">
                {message}
              </motion.span>
            ) : (
              message
            )}
          </motion.div>
        </AnimatePresence>
        <Button 
          color="purple" 
          onClick={onComplete} 
          disabled={isNextButtonDisabled}
          size="sm"
          className={`transition-all duration-300 ${isNextButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default OnboardingChat;