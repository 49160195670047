import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { getSupabase } from "../utils/supabase";
import {
  SiGoogleslides,
  SiGoogledocs,
  SiLinear,
  SiSlack,
  SiHubspot,
  SiAsana,
  SiGmail,
  SiGoogledrive,
} from "react-icons/si";
import { Button, Tooltip } from "flowbite-react";
import { toast } from "react-hot-toast";
import { useResizeObserverFix } from "../hooks/useResizeObserverFix";
import { AnimatePresence, motion } from "framer-motion";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const supabase = getSupabase();

const WelcomeIntegrations = ({ onComplete, onPrevious }) => {

  const { user } = useAuth();

  const [connectedApps, setConnectedApps] = useState([]);

  useResizeObserverFix();

  const integrationDescriptions = {
    GoogleSlides: "Integrate with Google Slides to manage your presentations.",
    GoogleDocs: "Integrate with Google Docs to manage your documents.",
    Linear: "Integrate with Linear to manage your project tasks.",
    Slack: "Integrate with Slack to manage your team communications.",
    Asana: "Integrate with Asana to manage your project tasks.",
    Hubspot: "Integrate with Hubspot to manage your company data.",
    GoogleMail: "Integrate with Gmail to manage your emails.",
    GoogleDrive: "Integrate with Google Drive to manage your files.",
  };

  const storeData = async (appName, data) => {
    if (!user) return;
    const { error } = await supabase.from("integrations").upsert(
      {
        user_id: user.id,
        name: appName,
        data: data,
        updated_at: new Date().toISOString(),
      },
      {
        onConflict: "name,user_id",
        returning: "minimal",
      }
    );

    if (error) {
      console.error("Error storing token:", error);
    } else {
      setConnectedApps((prev) => [...prev, { name: appName }]);
    }
  };

  const connectService = async (service) => {
    try {
      const servicePrefix = service.toLowerCase().substring(0, 6);
      const isGoogleService = servicePrefix === "google";

      const authEndpoint = isGoogleService
        ? `${backendUrl}/integrations/google/auth/${service}`
        : `${backendUrl}/integrations/${service.toLowerCase()}/auth`;

      const response = await fetch(authEndpoint, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Error fetching auth URL for ${service}`);
      }

      const { authUrl } = await response.json();
      const authWindow = window.open(
        authUrl,
        `${service} OAuth`,
        "width=500,height=600"
      );

      window.addEventListener(
        "message",
        async (event) => {
          if (event.data.type === `${service.toUpperCase()}_AUTH_SUCCESS`) {
            await storeData(service, event.data.data);
            authWindow.close();
            toast.success(`${service} connected successfully!`);
          } else if (event.data.type === `${service.toUpperCase()}_AUTH_ERROR`) {
            console.error(`Error setting up integration for ${service}:`, event.data.error);
            authWindow.close();
            toast.error(`Failed to connect ${service}`);
          }
        },
        false
      );
    } catch (error) {
      console.error(`${service} connection error:`, error);
      toast.error(`Error connecting to ${service}`);
    }
  };

  const connectSlack = () => {
    const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=7432820969396.7427451311141&scope=app_mentions:read,channels:read,chat:write,im:history,im:read,im:write,channels:history&user_scope=&redirect_uri=${encodeURIComponent(`${backendUrl}/integrations/slack/callback`)}`;

    const authWindow = window.open(
      slackAuthUrl,
      "Slack OAuth",
      "width=500,height=600"
    );

    window.addEventListener(
      "message",
      async (event) => {
        if (event.data.type === "SLACK_AUTH_SUCCESS") {
          await storeData("Slack", event.data.data);
          await storeSlackTokenAndTeamId(
            event.data.team_id,
            event.data.bot_token
          );
          authWindow.close();
          toast.success("Slack connected successfully!");
        } else if (event.data.type === "SLACK_AUTH_ERROR") {
          console.error(
            "Error setting up integration for Slack:",
            event.data.error
          );
          authWindow.close();
          toast.error("Failed to connect Slack");
        }
      },
      false
    );
  };

  const storeSlackTokenAndTeamId = async (teamId, botToken) => {
    const { error } = await supabase.from("slack_teams").upsert(
      {
        team_id: teamId,
        bot_token: botToken,
        updated_at: new Date().toISOString(),
      },
      {
        onConflict: "team_id",
        returning: "minimal",
      }
    );

    if (error) {
      console.error("Error storing Slack token:", error);
    }
  };

  const connectHubspot = () => {
    const hubspotAuthUrl = `https://app-eu1.hubspot.com/oauth/authorize?client_id=b142bb72-8a73-4ee4-868f-a1ab8dafc1c9&redirect_uri=https://meshfirm.com/oauth-callback&scope=sales-email-read%20oauth%20conversations.read%20conversations.write%20crm.objects.deals.read%20crm.objects.contacts.read`;

    const authWindow = window.open(hubspotAuthUrl, "Hubspot OAuth", "width=500,height=600");

    window.addEventListener(
      "message",
      async (event) => {
        if (event.data.type === "HUBSPOT_AUTH_SUCCESS") {
          await storeData("Hubspot", event.data.data);
          authWindow.close();
          toast.success("Hubspot connected successfully!");
        } else if (event.data.type === "HUBSPOT_AUTH_ERROR") {
          console.error("Error setting up integration for Hubspot:", event.data.error);
          authWindow.close();
          toast.error("Failed to connect Hubspot");
        }
      },
      false
    );
  };

  const connectAsana = async () => {
    try {
      const response = await fetch(`${backendUrl}/integrations/asana/auth`, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Error fetching Asana auth URL");
      }

      const { authUrl } = await response.json();
      const authWindow = window.open(
        authUrl,
        "Asana OAuth",
        "width=500,height=600"
      );

      window.addEventListener(
        "message",
        async (event) => {
          if (event.data.type === "ASANA_AUTH_SUCCESS") {
            const { data } = event.data;
            await storeData("Asana", {
              access_token: data.access_token,
              refresh_token: data.refresh_token,
              expires_at: Date.now() + data.expires_in * 1000,
            });
            authWindow.close();
            toast.success("Asana connected successfully!");
          } else if (event.data.type === "ASANA_AUTH_ERROR") {
            console.error(
              "Error setting up integration for Asana:",
              event.data.error
            );
            authWindow.close();
            toast.error("Failed to connect Asana");
          }
        },
        false
      );
    } catch (error) {
      console.error("Asana connection error:", error);
      toast.error("Error connecting to Asana");
    }
  };

  const integrations = [
    { name: "GoogleMail", Icon: SiGmail },
    { name: "GoogleDrive", Icon: SiGoogledrive },
    { name: "GoogleSlides", Icon: SiGoogleslides },
    { name: "GoogleDocs", Icon: SiGoogledocs },
    { name: "Linear", Icon: SiLinear },
    { name: "Slack", Icon: SiSlack },
    { name: "Hubspot", Icon: SiHubspot },
    { name: "Asana", Icon: SiAsana },
  ];

  return (
    <div className="space-y-8">
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4"
      >

        {integrations.map(({ name, Icon }, index) => {
          const isConnected = connectedApps.some(app => app.name === name);
          return (
            <Tooltip key={name} content={integrationDescriptions[name]}>
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}

                onClick={() => {
                  if (!isConnected) {
                    if (name === "Slack") {
                      connectSlack();
                    } else if (name === "Hubspot") {
                      connectHubspot();
                    } else if (name === "Asana") {
                      connectAsana();
                    } else {
                      connectService(name);
                    }
                  }
                }}
                className={`flex flex-col items-center justify-center p-4 rounded-lg transition-all duration-300 w-32  ${
                  isConnected
                    ? "bg-green-100 text-green-700 cursor-default"
                    : "bg-gray-100 hover:bg-gray-200 text-gray-700 hover:text-gray-900 hover:shadow-md"
                }`}
                disabled={isConnected}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Icon className="w-10 h-10 mb-2 text-brandColor" />
                <span className="text-xs font-medium text-center text-brandColor">{name}</span>
                {isConnected && (
                  <motion.span 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-xs mt-1 font-medium"
                  >
                    Connected
                  </motion.span>
                )}
              </motion.button>
            </Tooltip>
          );
        })}
      </motion.div>
      <div className="flex justify-between mt-8">
        <Button color="light" onClick={onPrevious} size="lg">
          Previous
        </Button>
        <Button color="purple" onClick={onComplete} size="lg">
          Complete Setup
        </Button>
      </div>
    </div>
  );
};

export default WelcomeIntegrations;