import React from "react";
import MarkdownRenderer from "./MarkdownRenderer";
import './DiscoveryChatHelp.css'

const DiscoveryChatHelp = ({ chatLog, userPrompt, chatLogEndRef }) => {
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex-grow overflow-y-auto p-1 sm:p-2 flex flex-col min-h-[50vh] sm:min-h-[30vh] md:min-h-[40vh] lg:min-h-[50vh]">
        {chatLog.map((message, idx) => {
          const isLastMessage = idx === chatLog.length - 1;

          return (
            <div
              className={`flex flex-col mb-2 w-full relative ${
                message.role === "human" ? "items-end" : "items-start"
              }`}
              key={idx}
            >
              <div
                className={`chat-message w-[98%] sm:w-[96%] flex flex-col relative p-2 sm:p-3 rounded-lg ${
                  message.role === "human"
                    ? "bg-lightBrand text-darkBrand"
                    : "bg-gray-100 text-gray-900"
                }`}
              >
                <MarkdownRenderer content={message.content} />
              </div>
              {isLastMessage && message.role === "ai" && (
                <div className="flex items-end mt-1 sm:mt-2">
                  <span className="w-2 h-2 bg-gray-500 rounded-full mr-1 animate-bounce"></span>
                  <span
                    className="w-2 h-2 bg-gray-500 rounded-full mr-1 animate-bounce"
                    style={{ animationDelay: "0.2s" }}
                  ></span>
                  <span
                    className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                    style={{ animationDelay: "0.4s" }}
                  ></span>
                </div>
              )}
            </div>
          );
        })}
        <div ref={chatLogEndRef} />
      </div>
    </div>
  );
};

export default DiscoveryChatHelp;