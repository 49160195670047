import React, { useEffect } from 'react'
import Greeting from './Greeting'
import NotificationBanner from './NotificationBanner'
import HeroPrompts from './HeroPrompts'
import { motion, useAnimation} from 'framer-motion'

function Hero({ onPromptSelect }) {

    const controls = useAnimation();

    useEffect(() => {
        controls.start({ opacity: 1 });
    }, [controls]);


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={controls}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-3 sm:px-4 py-4 md:py-8 sm:py-6 max-w-4xl"
    >
      <Greeting />
      <NotificationBanner />
      {/* <HeroPrompts onPromptSelect={onPromptSelect} /> */}
    </motion.div>
  )
}

export default Hero
