// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-message p {
  margin-bottom: 1rem; 
  line-height: 1.5;
}

.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/Onboarding.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,qDAAqD;AACvD;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,0CAA0C;EAC1C,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".chat-message p {\n  margin-bottom: 1rem; \n  line-height: 1.5;\n}\n\n.overflow-y-auto {\n  scrollbar-width: thin;\n  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;\n}\n\n.overflow-y-auto::-webkit-scrollbar {\n  width: 6px;\n}\n\n.overflow-y-auto::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.overflow-y-auto::-webkit-scrollbar-thumb {\n  background-color: rgba(155, 155, 155, 0.5);\n  border-radius: 20px;\n  border: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
