import socketIOClient from "socket.io-client";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const initializeZepTableSession = async (userId, fullName, content, sessionId) => {
  try {
    const socket = socketIOClient(backendUrl);
    
    // Send a specific table refresh prompt
    const refreshPrompt = `
    What are the biggest issues customers have complained about for my product?
    I want you to be very thorough in your collection and analysis of customer data. The more data you collect and analyse the better.
    Please categorise it into impact and effort, and organise the output as a table. 
    Add columns for source of complain, impact and effort.
    Output nothing but the table.
    `;
    
    socket.emit('discovery', {  
      message: refreshPrompt,
      sessionId: sessionId,
      userId: userId
    });

    // Wait for response
    const response = await new Promise((resolve, reject) => {
      let result = '';
      
      socket.on('token', (token) => {
        result += token;
      });

      socket.on('end', () => {
        socket.disconnect();
        resolve({ success: true, content: result });
      });

      socket.on('error', (error) => {
        socket.disconnect();
        reject(error);
      }); 

      // Add timeout
      setTimeout(() => {
        socket.disconnect();
        reject(new Error('Request timed out'));
      }, 120000);
    });

    return response;

  } catch (error) {
    console.error('Error in initializeZepTableSession:', error);
    return {
      success: false,
      error: error.message
    };
  }
};