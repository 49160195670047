import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { initializeZepTableSession } from '../utils/initializeZepTable';
import { v4 as uuidv4 } from 'uuid';
import Loading from './Loading';
import Error from './Error';

const DiscoveryTable = ({ content }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableContent, setTableContent] = useState(content); // Add state for table content

  const parseContent = useCallback((contentToParse) => {
    if (!contentToParse) return { headers: [], rows: [] };
    const lines = contentToParse.split('\n');
    const headers = lines[0].split('|').map((h) => h.trim());
    const dataRows = lines.slice(2).filter(line => line.trim() !== "");
    const rows = dataRows.map((line) => line.split("|").map((cell) => cell.trim()));
    return { headers, rows };
  }, []);
  
  useEffect(() => {
    const initializeSession = async () => {
      if (user) {
        try {
          const sessionId = uuidv4();
          const result = await initializeZepTableSession(
            user.id,
            user.user_metadata.full_name,
            content,
            sessionId
          );

          if (result.success) {
            setTableContent(result.content);
          } else {
            setError(new Error(result.error));
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
    };


    initializeSession();
  }, [user, content]); // Run when content changes
  
  const { headers, rows } = parseContent(tableContent); // Use tableContent instead of content

  if (loading) return <Loading message="Initializing discovery session..." />;
  if (error) return <Error err={error} />;

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {headers.map((header, index) => (
              <th key={index} scope="col" className="px-6 py-3">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
            >
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className={`px-6 py-4 ${cellIndex === 0 ? "font-medium text-gray-900 whitespace-nowrap dark:text-white" : ""}`}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

/* OLD:
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
*/

export default DiscoveryTable;
