import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "flowbite-react";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { FaUser, FaTools, FaUpload } from "react-icons/fa";

const bannerConfigs = [
  {
    title: "Check your Profile",
    description:
      "Update your information and preferences to get the most out of Sindre.",
    icon: FaUser,
    buttonText: "Go to Profile",
    route: "/profile",
  },
  {
    title: "Connect your tools",
    description:
      "Add your favorite tools to Sindre and unlock new capabilities.",
    icon: FaTools,
    buttonText: "Go to Integrations",
    route: "/integrations",
  },
  {
    title: "Add Knowledge",
    description:
      "Upload documents about your product and company to help Sindre understand your needs better.",
    icon: FaUpload,
    buttonText: "Upload Files",
    route: "/upload",
  },
];

function NotificationBanner() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(
    Math.floor(Math.random() * bannerConfigs.length)
  );

  const CurrentIcon = bannerConfigs[currentBannerIndex].icon;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex(
        (prevBannerIndex) => (prevBannerIndex + 1) % bannerConfigs.length
      );
    }, 10000); // 10 seconds

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    navigate(bannerConfigs[currentBannerIndex].route);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="w-full p-4">
      <Card className="overflow-hidden shadow-md border-l-3 w-full">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentBannerIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center space-x-4 px-4 py-2">
              <div className="flex-shrink-0">
                {React.createElement(bannerConfigs[currentBannerIndex].icon, {
                  className: "h-8 w-8 text-brandColor",
                })}
              </div>
              <div className="flex-grow">
                <h3 className="text-xl font-bold mb-1 text-brandColor">
                  {bannerConfigs[currentBannerIndex].title}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                  {bannerConfigs[currentBannerIndex].description}
                </p>
              </div>
              <div className="flex-shrink-0">
                <Button
                  outline
                  gradientDuoTone="purpleToBlue"
                  onClick={handleClick}
                  className="text-sm"
                >
                  {bannerConfigs[currentBannerIndex].buttonText}
                </Button>
              </div>
              <button
                onClick={handleClose}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <AiOutlineClose size={14} />
              </button>
            </div>
          </motion.div>
        </AnimatePresence>
      </Card>
    </div>
  );
}

export default NotificationBanner;
