import React from 'react';
import { motion } from 'framer-motion';

const OnboardingProgress = ({ steps, currentStep }) => {
  return (
    <div className="mb-8">
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: index < currentStep ? 1 : 0 }}
                className="absolute inset-0 rounded-full"
                style={{ zIndex: 0 }}
              />
              <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: index === currentStep - 1 ? [1, 1.2, 1] : 1 }}
                transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
                className={`w-10 h-10 mx-auto bg-white border-2 border-purple-500 rounded-full text-lg text-white flex items-center justify-center relative z-10`}
              >
                <span className={`text-sm font-medium ${index < currentStep ? 'text-purple-600' : 'text-gray-400'}`}>
                  {index + 1}
                </span>
              </motion.div>
              <div className="text-xs text-center mt-2 font-medium text-gray-500">
                {typeof step.title === 'string' ? step.title : step.title.props.children[1].props.children}
              </div>
            </div>
          ))}
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${(currentStep / steps.length) * 100}%` }}
            transition={{ duration: 0.5 }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingProgress;