import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import DiscoveryChatHelp from "./DiscoveryChatHelp";
import { getSupabase } from "../utils/supabase";
import { useAuth } from "../context/AuthContext";
import OpenAI from "openai";
import socketIOClient from "socket.io-client";
import PromptBar from "./PromptBar";
import { v4 as uuidv4 } from "uuid";
import { Button } from "flowbite-react";
import { motion, AnimatePresence } from "framer-motion";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const supabase = getSupabase();
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DiscoveryChat = ({ onComplete, userPrompt }) => {
  const { user } = useAuth();
  const chatLogEndRef = useRef(null);
  const [chatLog, setChatLog] = useState([]);
  const [conversationEnded, setConversationEnded] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [socket, setSocket] = useState(null);
  const [err, setErr] = useState(false);
  const [messageCounter, setMessageCounter] = useState(0);
  const [transcription, setTranscription] = useState("");
  const textareaRef = useRef(null);
  const [sessionId] = useState(uuidv4());
  const [userMessageCounter, setUserMessageCounter] = useState(0);
  const [inputPrompt, setInputPrompt] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState(userPrompt || '');
  // Initialize the zep session
  const initializeZepSession = async () => {
    if (user && userPrompt) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/init-zep-discovery-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.id,
            fullName: user.user_metadata.full_name,
            userPrompt: userPrompt,
            sessionId: sessionId,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to initialize Zep session');
        }

        const data = await response.json();
        console.log('Zep session initialized:', data.sessionId);
      } catch (error) {
        console.error('Error initializing Zep session:', error);
      }
    }
  }

  /*useEffect(() => {
    initializeZepSession();
  }, [userPrompt]);*/

  // Socket connection
  useEffect(() => {
    if (socket) {
      socket.on("start", (sessionId) => {
        console.log(`Started streaming for session ${sessionId}`);
        setIsStreaming(true);
      });

      socket.on("end", (sessionId) => {
        console.log(`Finished streaming for session ${sessionId}`);
        setIsStreaming(false);
        setMessageCounter(chatLog.length);
      });

      socket.on("token", (token) => {
        setChatLog((prevLog) => {
          const newLog = [...prevLog];
          const lastMessage = newLog[newLog.length - 1];
          if (lastMessage && lastMessage.role === "ai") {
            return [
              ...newLog.slice(0, -1),
              { ...lastMessage, content: lastMessage.content + token },
            ];
          } else {
            return [...newLog, { role: "ai", content: token }];
          }
        });
      });

      socket.on("error", (error) => {
        console.error("Error:", error);
      });
    }
  }, [socket]);

  // Scroll to bottom
  useEffect(() => scrollToBottom(), [chatLog]);

  const scrollToBottom = () => {
    chatLogEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentPrompt = `
    The user is having a conversation with you about Customer Discovery for their product. \n \n
    This is content from an AI that has done a Customer Discovery analysis:\n
    ${userPrompt || ""} \n \n

    This is the user's response: \n
    ${inputPrompt.trim()}
    `.trim();

    if (!isStreaming && inputPrompt.trim() !== "") {
      setMessageCounter((prev) => prev + 1);
      setInputPrompt("");
      
      const userMessage = { role: "human", content: inputPrompt };
      setChatLog((prevLog) => [...prevLog, userMessage]);
      
      try {
        const socket = socketIOClient(backendUrl);
        setSocket(socket);

        socket.emit('query', {  
          message: currentPrompt,
          sessionId: sessionId,
          userId: user.id  
        });

        // Handle socket events
        socket.on("end", () => {
          setConversationEnded(true); 
        });
      } catch (error) {
        setErr(true);
        console.error(error);
      }
    }
    else {
      console.error("Invalid input prompt:", currentPrompt)
    }
  };

  // Handle textarea input
  const handleTextareaInput = (e) => {
    const textarea = e.target;
    setInputPrompt(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const adjustTextareaHeight = (textarea) => {
    const lineHeight = 24;
    const maxHeight = lineHeight * 10;
  
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, maxHeight);
    textarea.style.height = `${newHeight}px`;
    textarea.style.overflowY = textarea.scrollHeight > maxHeight ? "scroll" : "hidden";
  };

  // Handle key down
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      return;
    }
  };


  return (
    <div className="flex flex-col h-full bg-white rounded-lg">
      {/* Added greeting text */}
      <div className="p-4 text-lg font-semibold text-gray-700">
        In this chat you can talk with the table to the left. 
        <br></br>
        What can I help you with?
      </div>
      <div className="flex-grow overflow-y-auto p-1 sm:p-2 flex flex-col">
        <DiscoveryChatHelp
          chatLog={chatLog}
          setChatLog={setChatLog}
          userPrompt={userPrompt}
          chatLogEndRef={chatLogEndRef}
        />
      </div>

      <div className="mt-1 sm:mt-2">
        <PromptBar
          inputPrompt={inputPrompt}
          handleTextareaInput={handleTextareaInput}
          handleSubmit={handleSubmit}
          handleKeyDown={handleKeyDown}
          setTranscription={setTranscription}
          isStreaming={isStreaming}
        />
      </div>
    </div>
  );
};

export default DiscoveryChat;