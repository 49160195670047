import React, { useState, useRef } from 'react';
import { FaMicrophone, FaStop } from 'react-icons/fa';

const SpeechToText = ({ setTranscription, isMobile }) => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioStreamRef = useRef(null); // Ref to store the media stream
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const audioTypes = [
    'audio/webm',
    'audio/webm;codecs=opus',
    'audio/ogg',
    'audio/ogg;codecs=opus',
    'audio/mp3',
    'audio/wav',
    'audio/aac',
    'audio/mpeg',
    'audio/mp4',
    'audio/x-m4a',
    'audio/vorbis'
  ];

  const rightAudioType = audioTypes.find(type => MediaRecorder.isTypeSupported(type));

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        audioStreamRef.current = stream; // Store the stream in the ref
        console.log(rightAudioType);
        const mimeType = rightAudioType;
        const mediaRecorder = new MediaRecorder(stream, { mimeType });
        console.log(mediaRecorder);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
          audioChunksRef.current = [];
          submitAudio(audioBlob);
          // Stop all tracks to release the microphone
          stream.getTracks().forEach(track => track.stop());
        };
        mediaRecorder.start(1000);
        setRecording(true);
      });
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach(track => track.stop());
      audioStreamRef.current = null;
    }
  };

  const submitAudio = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.mp3');
    console.log(formData);

    try {
      const response = await fetch(`${backendUrl}/transcription`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      setTranscription(result);
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
  };

  return (
    <div className='bg-brandColor rounded-full transform text-white hover:bg-darkBrand transition duration-100 ease-in-out mr-2 cursor-pointer p-3' onClick={recording ? stopRecording : startRecording}>
      {recording ? <FaStop size={isMobile ? 24 : 28} /> : <FaMicrophone size={isMobile ? 24 : 28} />}
    </div>
  );
};

export default SpeechToText;
