import React from "react";
import { Tabs, Card } from "flowbite-react";
import {
  HiUserCircle,
  HiClipboardList,
  HiCurrencyDollar,
  HiChartBar,
  HiLightBulb,
  HiDocumentText,
  HiViewList,
  HiCube,
  HiSparkles,
} from "react-icons/hi";
import { motion, AnimatePresence } from "framer-motion";

const categories = {
  Discover: [
    {
      icon: <HiUserCircle className="h-6 w-6" />,
      title: "User Needs Assessment",
      description:
        "Identify and prioritize user needs and pain points for the project",
      prompt:
        "Conduct a comprehensive user needs assessment for our project. Identify the top 3 user pain points and prioritize them based on impact and feasibility.",
    },
    {
      icon: <HiClipboardList className="h-6 w-6" />,
      title: "Market Research",
      description: "Analyze current market trends and competitor strategies",
      prompt:
        "Perform a detailed market analysis for our product. Identify key market trends, analyze our top 3 competitors, and suggest potential opportunities for differentiation.",
    },
  ],
  Define: [
    {
      icon: <HiLightBulb className="h-6 w-6" />,
      title: "Product Vision",
      description: "Create a clear and inspiring product vision statement",
      prompt: "Help me craft a compelling product vision statement that aligns with our business goals and inspires our team. Consider our target market, key benefits, and long-term aspirations.",
    },
    {
      icon: <HiDocumentText className="h-6 w-6" />,
      title: "Business Model Canvas",
      description: "Develop a Business Model Canvas for the product",
      prompt: "Guide me through creating a Business Model Canvas for our product. Include key partners, activities, resources, value propositions, customer relationships, channels, customer segments, cost structure, and revenue streams.",
    },
  ],
  Develop: [
    {
      icon: <HiViewList className="h-6 w-6" />,
      title: "Feature Prioritization",
      description: "Prioritize product features based on impact and effort",
      prompt: "Help me prioritize our product features using a value vs. effort matrix. Consider user needs, business goals, and development constraints to create a prioritized feature list.",
    },
    {
      icon: <HiCube className="h-6 w-6" />,
      title: "MVP Definition",
      description: "Define the Minimum Viable Product (MVP) for the project",
      prompt: "Guide me through defining our Minimum Viable Product (MVP). Include the core features that address our main user problems, key assumptions we're testing, and metrics to measure its success.",
    },
  ],
  Align: [
    {
      icon: <HiChartBar className="h-6 w-6" />,
      title: "OKRs",
      description: "Create Objectives and Key Results (OKRs) for the product",
      prompt: "Help me create a set of OKRs (Objectives and Key Results) for our product. Include 1-3 high-level objectives and 3-5 measurable key results for each objective that align with our overall business goals.",
    },
    {
      icon: <HiSparkles className="h-6 w-6" />,
      title: "KPIs",
      description: "Define Key Performance Indicators (KPIs) for the product",
      prompt: "Assist me in defining Key Performance Indicators (KPIs) for our product. Include metrics that measure user engagement, business performance, and product quality. Explain how each KPI relates to our overall product goals.",
    },
  ],
  Deliver: [
    {
      icon: <HiClipboardList className="h-6 w-6" />,
      title: "Project Timeline",
      description:
        "Create a detailed project timeline with key milestones and deliverables",
      prompt:
        "Develop a comprehensive project timeline for our product launch. Include key milestones, deliverables, and potential bottlenecks. Suggest strategies to optimize the timeline.",
    },
    {
      icon: <HiUserCircle className="h-6 w-6" />,
      title: "Resource Allocation",
      description:
        "Optimize resource allocation for efficient project delivery",
      prompt:
        "Analyze our current resource allocation for the project. Identify any gaps or inefficiencies, and propose an optimized resource allocation plan to improve project delivery.",
    },
  ],
  Sell: [
    {
      icon: <HiCurrencyDollar className="h-6 w-6" />,
      title: "Sales Strategy",
      description:
        "Develop a comprehensive sales strategy for the product or service",
      prompt:
        "Create a detailed sales strategy for our new product. Include target customer segments, pricing strategy, sales channels, and key performance indicators (KPIs) to measure success.",
    },
    {
      icon: <HiClipboardList className="h-6 w-6" />,
      title: "Pitch Deck Creation",
      description:
        "Create a compelling pitch deck for potential clients or stakeholders",
      prompt:
        "Outline a compelling pitch deck for our product. Include key sections such as problem statement, solution, market opportunity, competitive advantage, and financial projections.",
    },
  ],
  Grow: [
    {
      icon: <HiChartBar className="h-6 w-6" />,
      title: "Expansion Plan",
      description:
        "Formulate a strategy for market expansion and business growth",
      prompt:
        "Develop a strategic expansion plan for our business. Identify potential new markets, outline entry strategies, and suggest key metrics to track growth progress.",
    },
    {
      icon: <HiChartBar className="h-6 w-6" />,
      title: "Performance Metrics",
      description:
        "Define key performance indicators for tracking project and business growth",
      prompt:
        "Define a comprehensive set of key performance indicators (KPIs) for our project and overall business growth. Include both leading and lagging indicators, and suggest a dashboard layout for easy tracking.",
    },
  ],
};

function HeroPrompts({ onPromptSelect }) {


  const handlePromptClick = (prompt, category) => {
    onPromptSelect(prompt.prompt, category);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 1.5 }}
      className="w-full mt-4 sm:mt-6 md:mt-8"
    >
      <Tabs aria-label="Project Management Prompts" variant="underline" className="overflow-x-auto">
        {Object.entries(categories).map(([category, prompts]) => (
          <Tabs.Item
            key={category}
            title={category}
            icon={
              category === "Discover"
                ? HiUserCircle
                : category === "Deliver"
                  ? HiClipboardList
                  : category === "Sell"
                    ? HiCurrencyDollar
                    : HiChartBar
            }
          >
          <AnimatePresence>
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 mt-2"
              >
                {prompts.map((prompt, index) => (
                  <motion.div
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Card 
                      className="overflow-hidden shadow-md border-l-3 cursor-pointer h-full"
                      onClick={() => handlePromptClick(prompt, category)}
                    >
                      <div className="flex items-center p-3 sm:p-4">
                      <div className="mr-2 text-brandColor flex-shrink-0">
                          {React.cloneElement(prompt.icon, { className: "h-5 w-5 sm:h-6 sm:w-6" })} 
                        </div>
                        <div className="flex-grow">
                          <h3 className="text-base sm:text-lg font-bold mb-1 text-brandColor">{prompt.title}</h3>
                          <p className="text-xs sm:text-sm text-gray-600">{prompt.description}</p>
                        </div>
                      </div>
                    </Card>
                  </motion.div>
                ))}
              </motion.div>
            </AnimatePresence>
          </Tabs.Item>
        ))}
      </Tabs>
    </motion.div>
  );
}

export default HeroPrompts;
            