import React, { useRef, useEffect, useState, useCallback } from "react";
import SpeechToText from "./SpeechToText";
import { LuSend } from "react-icons/lu";

const PromptBar = ({
  inputPrompt,
  handleTextareaInput,
  handleSubmit,
  handleKeyDown,
  isStreaming,
  setTranscription
}) => {
  const textareaRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isFocused, setIsFocused] = useState(false);

  // Memoize the resize handler
  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  // Memoize the adjustTextareaHeight function
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const maxHeight = Math.floor(window.innerHeight * 0.4);
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);
      textarea.style.height = `${newHeight}px`;
      textarea.style.overflowY = textarea.scrollHeight > maxHeight ? 'auto' : 'hidden';
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputPrompt, adjustTextareaHeight]);

  // Memoize the getTextAreaStyles function
  const getTextAreaStyles = useCallback(() => {
    const baseHeight = isMobile ? 50 : 40;
    const fontSize = isMobile ? 18 : 16;
    const verticalPadding = 6;
    const maxHeight = Math.floor(window.innerHeight * 0.4);
    
    return {
      lineHeight: "1.3",
      padding: `${verticalPadding}px 8px`,
      paddingRight: isMobile ? "60px" : "70px",
      minHeight: `${baseHeight}px`,
      maxHeight: `${maxHeight}px`,
      width: "100%",
      boxSizing: "border-box",
      fontSize: `${fontSize}px`,
      overflowY: "auto",
      transition: "height 0.1s ease-out",
    };
  }, [isMobile]);

  const handleScroll = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  // Memoize the onChange handler
  const handleChange = useCallback((event) => {
    handleTextareaInput(event);
    adjustTextareaHeight();
  }, [handleTextareaInput, adjustTextareaHeight]);

  // Memoize the onKeyDown handler
  const handleKeyDownMemo = useCallback((event) => {
    handleKeyDown(event);
    adjustTextareaHeight();
  }, [handleKeyDown, adjustTextareaHeight]);

  return (
    <div className={`w-full mb-2 mx-auto px-1 sm:px-2 ${isMobile ? "max-w-[98%]" : "max-w-6xl"}`}>
      <form onSubmit={handleSubmit} className="relative">
        <div className="flex items-center bg-backgroundGray rounded-lg shadow-md p-1">
          <textarea
            id="chat-input"
            name="inputPrompt"
            value={inputPrompt}
            onChange={handleChange}
            onKeyDown={handleKeyDownMemo}
            onScroll={handleScroll}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={isFocused || inputPrompt ? "" : "Message Sindre"}
            className="flex-grow bg-transparent text-primary text-sm border-none justify-center focus:outline-none focus:ring-0 placeholder-gray-400 resize-none"
            ref={textareaRef}
            style={getTextAreaStyles()}
          />
          <SpeechToText isMobile={isMobile} setTranscription={setTranscription}/>
          <div className="flex items-center">
            <button
              type="submit"
              disabled={isStreaming}
              className="bg-brandColor rounded-full p-3 text-white hover:bg-darkBrand transition-all duration-300 active:scale-95"
            >
              <LuSend size={isMobile ? 24 : 28} />
            </button>
          </div>
        </div>
      </form>
      <style jsx>{`
        textarea::placeholder {
          position: absolute;
          top: 50%;
          left: 8px; 
          right: 60px;
          transform: translateY(-50%);
          transition: opacity 0.2s;
          opacity: ${isFocused || inputPrompt ? 0 : 1};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          pointer-events: none;
        }
        @media (max-width: 768px) {
          textarea::placeholder {
            font-size: 18px;
            right: 70px;
          }
        }
      `}</style>
    </div>
  );
};

// Memoize the entire component
export default React.memo(PromptBar);