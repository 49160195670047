import React, { useEffect, useState, useMemo } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getSupabase } from "../utils/supabase";
import { motion } from "framer-motion";
function Greeting() {
  const { user } = useAuth();
  const [fullName, setFullName] = useState("");
  const navigate = useNavigate();
  const supabase = getSupabase();

  useEffect(() => {
    if (!user) {
      navigate("/auth");
    } else {
      fetchUserName();
    }
  }, [user, navigate]);

  const fetchUserName = async () => {
    if (user?.id) {
      try {
        const { data, error } = await supabase
          .from("users")
          .select("full_name")
          .eq("id", user.id)
          .single();

        if (error) throw error;
        setFullName(data.full_name || "");
      } catch (error) {
        console.error("Error fetching user name:", error);
        setFullName("");
      }
    }
  };

 // Greeting based on the time of day
 const greeting = useMemo(() => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning"
    if (hour < 18) return "Good afternoon"
    return "Good evening"
  }, [])

  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-5xl font-bold mb-2">
        <span className="bg-gradient-to-br from-darkBrand to-brandColor text-transparent bg-clip-text opacity-90">
          {greeting},{" "}
        </span>
        <span className="bg-gradient-to-br from-brandColor to-lightBrand text-transparent bg-clip-text">
          {fullName}
        </span>
      </div>
      <p className="text-3xl font-normal text-gray-400 mt-2 mb-6">
        How can I help you today?
      </p>
    </motion.div>
  );
}

export default Greeting;
