import React, { useState, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import { FaCloudUploadAlt } from 'react-icons/fa';
import { FileInput, Label, Progress, Button } from "flowbite-react";
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { PDFDocument } from "pdf-lib";
import * as XLSX from "xlsx";

const WelcomeFileUpload = ({ onComplete, onPrevious }) => {

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { user } = useAuth();

  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      uploadFiles(selectedFiles);
    }
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer?.files);
    if (droppedFiles.length > 0) {
      uploadFiles(droppedFiles);
    } else {
      toast.error('Error: No file selected');
    }
  }, []);

  const convertToPdf = async (file) => {
    const fileType = file.type;
    const fileName = file.name;

    if (fileType === 'application/pdf') {
      return file;
    }

    try {
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([600, 800]);

      if (fileType.includes('image')) {
        const imageBytes = await file.arrayBuffer();
        let image;
        if (fileType.includes('png')) {
          image = await pdfDoc.embedPng(imageBytes);
        } else {
          image = await pdfDoc.embedJpg(imageBytes);
        }
        const { width, height } = image.scale(1);
        page.drawImage(image, {
          x: 0,
          y: 0,
          width: Math.min(width, page.getWidth()),
          height: Math.min(height, page.getHeight()),
        });
      } else if (fileType.includes('text') || fileName.endsWith('.txt') || fileName.endsWith('.csv')) {
        const text = await file.text();
        page.drawText(text, { x: 50, y: page.getHeight() - 50, size: 12 });
      } else if (fileType.includes('spreadsheet') || fileName.endsWith('.xlsx') || fileName.endsWith('.xls')) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvContent = XLSX.utils.sheet_to_csv(worksheet);
        
        const lines = csvContent.split('\n');
        lines.forEach((line, index) => {
          page.drawText(line, { x: 50, y: page.getHeight() - 50 - (index * 20), size: 10 });
        });
      } else if (fileType.includes('json')) {
        const text = await file.text();
        const jsonData = JSON.parse(text);
        const formattedJson = JSON.stringify(jsonData, null, 2);
        const lines = formattedJson.split('\n');
        lines.forEach((line, index) => {
          page.drawText(line, { x: 50, y: page.getHeight() - 50 - (index * 20), size: 10 });
        });
      } else {
        throw new Error('Unsupported file type');
      }

      const pdfBytes = await pdfDoc.save();
      return new File([pdfBytes], `${fileName}.pdf`, { type: 'application/pdf' });
    } catch (error) {
      console.error(`Error converting ${fileName} to PDF:`, error);
      throw new Error(`Failed to convert ${fileName} to PDF: ${error.message}`);
    }
  };

  const uploadFiles = async (files) => {
    setUploadingFiles(files);
    for (const file of files) {
      try {
        const convertedFile = await convertToPdf(file);
        await uploadFile(convertedFile);
      } catch (error) {
        toast.error(`Error processing ${file.name}: ${error.message}`);
      }
    }
    setUploadingFiles([]);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', user.id);

    try {
      await axios.post(`${backendUrl}/upload/pdf`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prev => ({...prev, [file.name]: progress}));
        },
      });

      toast.success(`Success: ${file.name} uploaded successfully!`);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      setUploadProgress(prev => ({...prev, [file.name]: 0}));
    }
  };

  return (
    <div className="bg-white flex flex-col items-center justify-start w-full max-w-md mx-auto p-5 mb-4 rounded-md shadow-md">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        className="flex w-full items-center justify-center mb-6"
      >
        <Label
          htmlFor="dropzone-file"
          className={`flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed ${
            isDragging ? "border-brandColor bg-teal-50" : "border-gray-300 bg-gray-50 hover:bg-gray-100"
          } transition-colors duration-300 ease-in-out`}
        >
          <div className="flex flex-col items-center justify-center pb-6 pt-5">
            <FaCloudUploadAlt className={`mb-3 h-10 w-10 ${isDragging ? "text-brandColor" : "text-gray-500"}`} />
            <p className={`mb-2 text-sm ${isDragging ? "text-brandColor" : "text-gray-500"}`}>
              <span className="font-semibold">{isDragging ? "Drop the file here" : "Click to upload"}</span>{" "}
              {!isDragging && "or drag and drop"}
            </p>
            <p className={`text-xs ${isDragging ? "text-brandColor" : "text-gray-500"}`}>PDF, DOCX, XLSX, PPTX, TXT, CSV, XLS, JSON files</p>
          </div>
          <FileInput
            id="dropzone-file"
            className="hidden"
            onChange={handleFileChange}
            accept=".pdf,.docx,.xlsx,.pptx,.txt,.csv,.xls,.json"
            multiple
          />
        </Label>
      </motion.div>

      <AnimatePresence>
        {uploadingFiles.length > 0 && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="w-full mt-6"
          >
            {uploadingFiles.map((file, index) => (
              <div key={index} className="mb-4">
                <p className="text-sm text-gray-600 mb-2">Uploading: {file.name}</p>
                <Progress progress={uploadProgress[file.name] || 0} color="purple" size="lg" />
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <div className="flex justify-between w-full mt-8">
        <Button color="light" onClick={onPrevious} size="lg">
          Previous
        </Button>
        <Button color="purple" onClick={onComplete} size="lg">
          Next
        </Button>
      </div>
    </div>
  );
};

export default WelcomeFileUpload;