import React, { useEffect, useState, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { generateSuggestions } from "../utils/suggestionGenerator";

function NextStepsPrompts({ chatLog, onSelect, isUserTyping, isAITyping, userId }) {

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const suggestionTimeoutRef = useRef(null);
  const lastProcessedMessageIndex = useRef(-1);

  // fetch suggestions from the server 
  const fetchSuggestions = useCallback(async () => {
    const lastAIMessage = chatLog.findLast(message => message.role === "ai" || message.role === "assistant");
    if (lastAIMessage && lastProcessedMessageIndex.current !== chatLog.length - 1) {
      const newSuggestions = await generateSuggestions([lastAIMessage], userId);
      setSuggestions(newSuggestions);
      setShowSuggestions(true);
      lastProcessedMessageIndex.current = chatLog.length - 1;
    }
  }, [chatLog]);

  // show suggestions if the last message is from AI and user is not typing and AI is not typing
  useEffect(() => {
    const lastMessage = chatLog[chatLog.length - 1];
    
    // Clear any existing timeout
    if (suggestionTimeoutRef.current) {
      clearTimeout(suggestionTimeoutRef.current);
    }

    // If the last message is from AI, user is not typing, and AI is not typing, set a timeout to show suggestions
    if (lastMessage && (lastMessage.role === "ai" || lastMessage.role === "assistant") && !isUserTyping && !isAITyping) {
      suggestionTimeoutRef.current = setTimeout(() => {
        fetchSuggestions();
      }, 1000); // 1 second delay
    } else {
      setShowSuggestions(false);
    }

    // Cleanup function to clear the timeout
    return () => {
      if (suggestionTimeoutRef.current) {
        clearTimeout(suggestionTimeoutRef.current);
      }
    };
  }, [chatLog, isUserTyping, isAITyping, fetchSuggestions]);
  
  // animations
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 0.9, 
      y: 0, 
      transition: { 
        opacity: { duration: 0.3, ease: "easeInOut"}, 
        y: { duration: 0.4, ease: "easeInOut"},
        staggerChildren: 0.5, 
        delayChildren: 0.2 
      } 
    },
    exit: { 
      opacity: 0, 
      y: 20, 
      transition: { 
        opacity: { duration: 0.3, ease: "easeInOut"}, 
        y: { duration: 0.4, ease: "easeInOut"} 
      } 
    }
  };

  const bubbleVariants = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0, 
      opacity: 0.9, 
      transition: { 
        duration: 0.3, 
        ease: "easeInOut",
        delay: ({ custom }) => custom * 0.5 // delay each suggestion by 0.5 seconds based on its index
      } 
    },
    hover: { scale: 1.02, transition: { type: "spring", stiffness: 200 } },
  };

  return (
    <AnimatePresence>
      {showSuggestions && suggestions.length > 0 && (
        <motion.div
          className="flex flex-wrap justify-center gap-2 sm:gap-3 mt-2"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={containerVariants}
        >
          {suggestions.map((suggestion, index) => (
            <motion.div
              key={index}
              className="cursor-pointer bg-lightBrand text-darkBrand text-[10px] sm:text-xs font-medium px-1.5 py-0.5 sm:px-2 sm:py-1 rounded-md hover:bg-darkBrand hover:text-white transition-colors duration-200 shadow-sm"  
              variants={bubbleVariants}
              custom={index} // Pass the index as a custom property
              whileHover="hover"
              onClick={() => {
                onSelect(suggestion);
                setShowSuggestions(false);
              }}
            >
              {suggestion}
            </motion.div>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default NextStepsPrompts;
